import authrequest from '@/utils/authrequest'

export function getApp(params){
    return authrequest({
        url: '/api/app/app-manager/index-list',
        method: 'get',
        params
    })
}

export async function addApp(data){
    return await authrequest({
        url: '/api/app/app-manager',
        method: 'post',
        data
    })
}

export async function updateApp(data){
    return await authrequest({
        url: '/api/app/app-manager',
        method: 'put',
        data
    })
}

export async function checkApp(data){
    return await authrequest({
        url: '/api/app/app-manager/check',
        method: 'post',
        data
    })
}

export async function addForm(data){
    return await authrequest({
        url: '/api/app/form',
        method: 'post',
        data
    })
}

export async function addFlow(data){
    return await authrequest({
        url: '/api/app/flow',
        method: 'post',
        data
    })
}

export async function addAppForm(data){
    return await authrequest({
        url: '/api/app/app-manager/form',
        method: 'post',
        data
    })
}

export async function deleteAppForm(params){
    return await authrequest({
        url: '/api/app/app-manager/form',
        method: 'delete',
        params
    })
}

export async function deleteApp(id){
    return await authrequest({
        url: '/api/app/app-manager/' + id,
        method: 'delete'
    })
}


export async function addAppFlow(data){
    return await authrequest({
        url: '/api/app/app-manager/flow',
        method: 'post',
        data
    })
}

export function get(id){
    return authrequest({
        url: '/api/app/app-manager/' + id,
        method: 'get'
    })
}

export function getMenu(){
    return authrequest({
        url: '/api/app/app-manager/menus-list',
        method: 'get'
    })
}

export function updateSoft(file, params){
    const formData = new FormData();
    formData.append("input", file);
    return authrequest({
        url: '/api/app/upload/update-soft',
        method: 'post',
        data: formData,
        params
    })
}

export function getExtData(id){
    return authrequest({
        url: '/api/app/app-manager/' + id + '/ext',
        method: 'get'
    })
}


export function getExt(id){
    return authrequest({
        url: '/api/app/app-manager/'+ id +'/ext-list',
        method: 'get'
    })
}


export async function addAppExt(data){
    return await authrequest({
        url: '/api/app/app-manager/ext',
        method: 'post',
        data
    })
}

export async function deleteAppExt(params){
    return await authrequest({
        url: '/api/app/app-manager/ext',
        method: 'delete',
        params
    })
}